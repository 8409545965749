import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import projectService from "../../services/project.service";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../redux/actions/message";
import logService from "../../services/log.service";

const DashboardLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState(null);
  const [totalHoursLogged, setTotalHoursLogged] = useState(null);
  const [sortLastSyncronized, setSortLastSyncronized] = useState(-1);
  const [sortBulkHours, setSortBulkHours] = useState(-1);
  const [syncLoadingRow, setSyncLoadingRow] = useState(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [logAll, setLogAll] = useState(null);
  const [logLimit, setLogLimit] = useState(100);

  const tableColumns = [
    {
      Header: "Board Name",
      accessor: "boardName", // accessor is the "key" in the data
    },
    {
      Header: "Board Id",
      accessor: "boardId",
    },
    {
      Header: "Last Tracked",
      accessor: "lastTracked",
      sortType: (rowA, rowB, columnId) => {
        const lastTrackedA = moment(
          rowA.values[columnId],
          "DD MMMM YYYY, HH:mm"
        );
        const lastTrackedB = moment(
          rowB.values[columnId],
          "DD MMMM YYYY, HH:mm"
        );
        return lastTrackedA - lastTrackedB;
      },
    },
    {
      Header: "Last Syncronized",
      accessor: "lastSynchronized",
      // sortType: (rowA, rowB, columnId) => {
      //   const months = {
      //     "January": '01',
      //     "February": '02',
      //     "March": '03',
      //     "April": '04',
      //     "May": '05',
      //     "June": '06',
      //     "July": '07',
      //     "August": '08',
      //     "September": '09',
      //     "October": '10',
      //     "November": '11',
      //     "December": '12'
      //   };
      //   const [dayA, monthA, yearA, timeA] = rowA.values[columnId].split(/[\s,]+/);
      //   const [dayB, monthB, yearB, timeB] = rowB.values[columnId].split(/[\s,]+/);
      //   const dateA =new Date(`${yearA}-${months[monthA]}-${dayA}T${timeA}:00.000Z`);
      //   const dateB = new Date(`${yearB}-${months[monthB]}-${dayB}T${timeB}:00.000Z`);
      //   return dateA.getTime() - dateB.getTime();
      // },

      sortType: (rowA, rowB, columnId) => {
        const dateA = moment(rowA.values[columnId], "DD MMMM YYYY, HH:mm");
        const dateB = moment(rowB.values[columnId], "DD MMMM YYYY, HH:mm");
        return dateA - dateB;
      },
    },
    {
      Header: "Time Logged",
      accessor: "totalLogged",
      sortType: (rowA, rowB, columnId) => {
        // Mengambil nilai waktu dari kolom
        const timeA = rowA.values[columnId];
        const timeB = rowB.values[columnId];

        // Fungsi untuk mengonversi nilai waktu menjadi jumlah menit
        const convertToMinutes = (time) => {
          const [hours, minutes] = time.split(" ");
          let totalMinutes = 0;
          if (hours.includes("h")) {
            totalMinutes += parseInt(hours.replace("h", ""), 10) * 60;
          }
          if (minutes.includes("m")) {
            totalMinutes += parseInt(minutes.replace("m", ""), 10);
          }
          return totalMinutes;
        };

        // Mengonversi nilai waktu menjadi jumlah menit
        const minutesA = convertToMinutes(timeA);
        const minutesB = convertToMinutes(timeB);

        // Membandingkan jumlah menit untuk sorting
        return minutesA - minutesB;
      },
    },
    {
      Header: "Hours Remaining",
      accessor: "bulkHoursLeft",
      sortType: (rowA, rowB, columnId) => {
        // Mengambil nilai waktu dari kolom
        const timeA = rowA.values[columnId];
        const timeB = rowB.values[columnId];

        // Fungsi untuk mengonversi nilai waktu menjadi jumlah menit
        const convertToMinutes = (time) => {
          const [hours, minutes] = time.split(" ");
          let totalMinutes = 0;
          if (hours.includes("h")) {
            totalMinutes += parseInt(hours.replace("h", ""), 10) * 60;
          }
          if (minutes.includes("m")) {
            totalMinutes += parseInt(minutes.replace("m", ""), 10);
          }
          return totalMinutes;
        };

        // Mengonversi nilai waktu menjadi jumlah menit
        const minutesA = convertToMinutes(timeA);
        const minutesB = convertToMinutes(timeB);

        // Membandingkan jumlah menit untuk sorting
        return minutesA - minutesB;
      },
    },
    {
      Header: "Status",
      accessor: "isUpToDate",
      Cell: ({ cell: { value, row } }) =>
        syncLoadingRow === row.original.boardId ? (
          <Spinner animation="border" size="sm" />
        ) : value ? (
          "Up to date"
        ) : (
          <Button
            variant="link"
            className="text-danger"
            onClick={(e) => {
              e.preventDefault();
              syncProject(row.original.boardId);
            }}
            style={{ padding: 0 }}
          >
            Sync
          </Button>
        ),
      sortType: (rowA, rowB, columnId) => {
        const statusA = rowA.values[columnId] ? 1 : 0;
        const statusB = rowB.values[columnId] ? 1 : 0;
        return statusA - statusB;
      },
    },
    {
      id: "boardUrl",
      Header: "Board URL",
      accessor: "boardUrl",
      disableGlobalFilter: true,
    },
  ];

  const hiddenColumns = ["boardUrl"];

  const tableActionButtons = [
    {
      name: "See Detail",
      variant: "blue",
      action: (row) => navigate("/project-detail/" + row.boardId),
    },
    {
      name: "Open Board",
      variant: "darkblue",
      action: (row) => window.open(row.boardUrl),
    },
  ];

  useEffect(() => {
    setProjects(null);
    projectService
      .allProjects(sortLastSyncronized, sortBulkHours)
      .then((res) => {
        setProjects(res.data.result.projects);
        setTotalHoursLogged(res.data.result.totalHoursLogged);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to retrieve projects"));
      });
    return () => {};
  }, [sortLastSyncronized, sortBulkHours, dispatch]);

  const reloadProjects = () => {
    projectService
      .allProjects(sortLastSyncronized, sortBulkHours)
      .then((res) => {
        setProjects(res.data.result.projects);
        setTotalHoursLogged(res.data.result.totalHoursLogged);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to retrieve projects"));
      });
  };

  const syncProject = (boardId) => {
    setSyncLoadingRow(boardId);
    projectService
      .synchronizeProject(boardId)
      .then((res) => {
        if (res.data.success) {
          setProjects(null);
          dispatch(setSuccessMessage(res.data.message));
          reloadProjects();
        } else {
          dispatch(setErrorMessage(res.data.message));
        }
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to synchronize the project"));
      })
      .finally(() => {
        setSyncLoadingRow(null);
      });
  };

  const loadAllLog = useCallback(() => {
    logService
      .logAll(logLimit)
      .then((res) => {
        setLogAll(res.data.data);
      })
      .catch(() => {
        dispatch(setErrorMessage("Failed to load the log detail"));
      });
  }, [logLimit, dispatch]);


  useEffect(() => {
    loadAllLog();
    return () => {};
  }, [loadAllLog]);


    // Function to format date
    const formatDateTime = (isoString) => {
      const date = new Date(isoString);
  
      // Format date as DD-MM-YYYY
      const formattedDate = date
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          timeZone: "UTC",
        })
        .replace(/\//g, "-");
  
      // Format time as HH:MM AM/PM
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "UTC",
      });
  
      return {
        date: formattedDate,
        time: formattedTime + " UTC",
      };
    };
  return {
    logAll,
    logLimit,
    setLogLimit,
    formatDateTime,
    tableColumns,
    tableActionButtons,
    hiddenColumns,
    projects,
    totalHoursLogged,
    sortLastSyncronized,
    sortBulkHours,
    syncLoadingRow,
    isHistoryOpen,
    setIsHistoryOpen,
    navigate,
    setSortLastSyncronized,
    setSortBulkHours,
  };
};

export default DashboardLogic;
