import React from "react";
import { Card } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import Button from "react-bootstrap/Button";
import "./ActivityLog.css";

export default function ActivityLog({
  isOpen,
  onClose,
  fetchData,
  formatDateTime,
  maxHeightContent,
  limit,
  setLimit,
}) {
  return (
    <Card
    className="bg-light mobile-sidebar top-0 end-0 h-100 transition-all duration-300 ease-in-out"
      style={{
        width: isOpen ? "20%" : "0%",
        transform: isOpen ? "translateX(0)" : "translateX(100%)",
        opacity: isOpen ? 1 : 0,
        visibility: isOpen ? "visible" : "hidden",
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        height: "calc(100vh - 20px)",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <Card.Header className="bg-white border-bottom d-flex justify-content-between align-items-center">
        <h5 className="mb-0">Activity Log</h5>
        <Button
          variant="link"
          className="p-0 text-dark close-button"
          onClick={onClose}
        >
          <IoMdClose size={20} />
        </Button>
      </Card.Header>

      <div
        className="overflow-y-auto flex-grow-1 h-100"
        style={{
          maxHeight: maxHeightContent,
          overflowY: "auto",
          minHeight: "120vh",
        }}
      >
        {fetchData?.length > 0 ? (
          fetchData.map((log, index) => {
            const { date, time } = formatDateTime(log.createdAt);
            const createdByName =
              log.action === "cronjob"
                ? "system"
                : log.createdBy?.length > 0
                ? log.createdBy?.split("@")[0]
                : "admin";

            const logAction =
              log.action === "cronjob"
                ? " was updated by "
                : log.action === "project [PATCH]"
                ? " was manually synchronized by "
                : log.action === "project [PUT]"
                ? ` was adjusted by ${log.bulkHour} hours and ${log.bulkMinute} minutes by `
                : log.action === "add bulkhour [PUT]"
                ? ` was increased by ${log.bulkHour} hours and ${log.bulkMinute} minutes by `
                : log.action === "remove bulkhour [POST]" &&
                  ` was reduced by ${log.bulkHour} hours and ${log.bulkMinute} minutes by `;

            return (
              <ul key={index} className="timeline" style={{ fontSize: "14px" }}>
                <li className="pe-2">
                  <div className="d-flex align-items-center">
                    <span className="me-2"></span>
                    <small className="text-muted">
                      {date} | {time}
                    </small>
                  </div>
                  <div className="mt-2">
                    <span
                      className="px-2 py-1"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#8ef3fa",
                      }}
                    >
                      {log.updateSecondRemainingFormatted}
                    </span>
                  </div>
                  <div className="mt-2">
                    <small>
                      Bulk Hours on <b>{log.boardName}</b> {logAction}
                      <b>
                        {createdByName}
                      </b>
                    </small>
                  </div>
                </li>
                <li></li>
              </ul>
            );
          })
        ) : (
          <Card.Body className="d-flex justify-content-center h-100 align-items-center p-4">
            There is no data found.
          </Card.Body>
        )}
        {fetchData?.length > 0 && (
          <button
            className="btn btn-link w-100 text-success"
            onClick={() => setLimit(limit + 50)}
          >
            View More
          </button>
        )}
      </div>
      <Card.Footer className="bg-light border-top text-center">
        <span className="text-muted py-2">
          Total Newest Log ({fetchData && fetchData.length})
        </span>
      </Card.Footer>
    </Card>
  );
}
