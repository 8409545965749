import api from "./api";

const logAll = (limit) => {
  return api.get("logs?limit="+limit);
};

const logDetail = (boardId, limit) => {
  return api.get("logs/" + boardId + "?limit="+limit);
};

const logService = {
  logAll,
  logDetail,
};

export default logService;
