import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header/Header";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Placeholder from "react-bootstrap/Placeholder";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import ProjectDetailLogic from "./ProjectDetailLogic";
import DataTable from "../../components/DataTable/DataTable";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import ActivityLog from "../../components/ActivityLog/ActivityLog";

export default function ProjectDetail() {
  const logic = ProjectDetailLogic();
  
  return (
    <div className="d-flex justify-content-start h-100">
      {/* <Container className={`${logic.isHistoryOpen ? "w-75" : ""}`}> */}
      <Container
        className="transition-all duration-300 ease-in-out"
        style={{
          width: logic.isHistoryOpen ? "80%" : "100%",
        }}
      >
        <Header />
        <Row className="my-3">
          <Navbar variant="lightgrey">
            {logic.projectDetail ? (
              <Stack direction="horizontal" className="align-items-center">
                <div className="fw-bold me-1">
                  {logic.projectDetail.boardName}:
                </div>
                <div>{logic.projectDetail.totalLogged} Logged</div>
              </Stack>
            ) : (
              <Placeholder xs={3} />
            )}

            <Navbar.Collapse className="justify-content-end hover-on-small">
              <Nav>
                <Nav.Link
                  className={logic.selectedFilter === "ALL" && "bg-lightblue"}
                  disabled={logic.selectedFilter === "ALL"}
                  onClick={logic.filterAllSelected}
                >
                  All
                </Nav.Link>
                <Nav.Link
                  className={logic.selectedFilter === "WEEK" && "bg-lightblue"}
                  disabled={logic.selectedFilter === "WEEK"}
                  onClick={logic.filterWeekSelected}
                >
                  Week
                </Nav.Link>
                <Nav.Link
                  className={logic.selectedFilter === "MONTH" && "bg-lightblue"}
                  disabled={logic.selectedFilter === "MONTH"}
                  onClick={logic.filterMonthSelected}
                >
                  Month
                </Nav.Link>
                <NavDropdown
                  id="dropdown-basic"
                  title="Date Range"
                  align="end"
                  className={
                    logic.selectedFilter === "DATE_RANGE" && "bg-lightblue"
                  }
                  disabled={logic.selectedFilter === "DATE_RANGE"}
                >
                  <DateRangePicker
                    ranges={[logic.date]}
                    onChange={logic.handleDateRangeSelect}
                    showMonthAndYearPickers={false}
                    staticRanges={[]}
                    inputRanges={[]}
                    rangeColors={["#003A96"]}
                  />
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
        <Row>
          <Col>
            {logic.projectDetail ? (
              <Stack direction="horizontal">
                <div className="fw-bold me-1">Status: </div>
                {logic.projectDetail.isUpToDate ? (
                  <Stack direction="horizontal" gap={1}>
                    <span className="dot-success"></span>
                    <div>Up to date</div>
                  </Stack>
                ) : (
                  <>
                    <span className="dot-warning"></span>
                    <div className="mx-1">Not up to date</div>
                    <Button variant="blue" onClick={logic.syncProject}>
                      {logic.syncLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Sync Project"
                      )}
                    </Button>
                  </>
                )}
              </Stack>
            ) : (
              <Placeholder as="p" animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {logic.projectDetail ? (
              <Stack direction="horizontal">
                <div className="fw-bold me-1">Last Email Sent: </div>
                <Stack direction="horizontal" gap={1}>
                  <div>{logic.projectDetail.lastEmailSent}</div>
                </Stack>
              </Stack>
            ) : (
              <Placeholder as="p" animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            )}
          </Col>
        </Row>
        <Row className="my-3 d-flex flex-row align-items-center">
          <Col sm={4}>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="blue"
                onClick={logic.updateProjectDetail}
                style={{ minWidth: "200px" }}
              >
                Update Project Detail
              </Button>
              <Button variant="darkblue" onClick={logic.back}>
                Back
              </Button>
            </Stack>
          </Col>
          <Col sm={4} className="d-flex flex-row justify-content-center">
            {logic.selectedFilter === "WEEK" ? (
              logic.date.startDate && (
                <Stack direction="horizontal" gap={2}>
                  <Button
                    className="shadow-sm"
                    variant="light"
                    onClick={logic.prevWeek}
                  >
                    &#60;
                  </Button>
                  <div className="text-nowrap">
                    {format(logic.date.startDate, "dd MMMM")} -{" "}
                    {format(logic.date.endDate, "dd MMMM")}
                  </div>
                  <Button
                    className="shadow-sm"
                    variant="light"
                    onClick={logic.nextWeek}
                  >
                    &#62;
                  </Button>
                  <div className="fst-italic" style={{ fontSize: 8 }}>
                    the date is from Saturday - Friday
                  </div>
                </Stack>
              )
            ) : logic.selectedFilter === "MONTH" ? (
              <Stack direction="horizontal" gap={2}>
                <div>Showing for: </div>
                <div>
                  <Form.Select
                    className="shadow border-0"
                    value={logic.month}
                    onChange={(e) => logic.handleChangeMonth(e.target.value)}
                  >
                    {logic.months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Stack>
            ) : logic.selectedFilter === "DATE_RANGE" ? (
              <Stack direction="horizontal" gap={2}>
                <div>Showing Task from</div>
                <div className="fw-bold">
                  {format(logic.date.startDate, "dd MMMM")}
                </div>{" "}
                to
                <div className="fw-bold">
                  {format(logic.date.endDate, "dd MMMM")}.
                </div>
                <NavDropdown
                  id="dropdown-basic"
                  title="Change Date"
                  align="end"
                >
                  <DateRangePicker
                    ranges={[logic.date]}
                    onChange={logic.handleDateRangeSelect}
                    showMonthAndYearPickers={false}
                    staticRanges={[]}
                    inputRanges={[]}
                    rangeColors={["#003A96"]}
                  />
                </NavDropdown>
              </Stack>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={4} className="d-flex justify-content-end pe-3">
            <Button
              variant="secondary"
              onClick={() => logic.setIsHistoryOpen(!logic.isHistoryOpen)}
            >
              Activity Log
            </Button>
          </Col>
        </Row>
        <DataTable columns={logic.tableColumns} data={logic.tasks} />
      </Container>

      <ActivityLog
        isOpen={logic.isHistoryOpen}
        onClose={() => logic.setIsHistoryOpen(false)}
        fetchData={logic.logDetail}
        formatDateTime={logic.formatDateTime}
        maxHeightContent={'calc(100vh + 110px)'}
        limit={logic.logLimit}
        setLimit={logic.setLogLimit}
      />
    </div>
  );
}
