import api from "./api";

const getNetworkStrength = () => {
  if ('connection' in navigator) {
    const connection = navigator.connection;
  
    return connection;
  } else {
    return 'unknown';
  }
  
};

const networkStrength = getNetworkStrength(); 

const allProjects = (lastSynchronized, bulkHours) =>
  api.get("projects?lastSynchronized=" + lastSynchronized + "&bulkHours=" + bulkHours, {
    headers: {
      'Content-Type': 'application/json',
      'x-network-strength-type': networkStrength.effectiveType,
      'x-network-strength-download-speed': networkStrength.downlink,
      'x-network-strength-rtt': networkStrength.rtt,
    },
  }
);
const projectDetail = (boardId, startDate, endDate) => {
  if (startDate && endDate) {
    return api.get(
      "projects/" + boardId + "?startDate=" + startDate + "&endDate=" + endDate, {
        headers: {
          'Content-Type': 'application/json',
          'x-network-strength-type': networkStrength.effectiveType,
          'x-network-strength-download-speed': networkStrength.downlink,
          'x-network-strength-rtt': networkStrength.rtt,
        },
      }
    );
  } else {
    return api.get("projects/" + boardId, {
      headers: {
        'Content-Type': 'application/json',
        'x-network-strength-type': networkStrength.effectiveType,
        'x-network-strength-download-speed': networkStrength.downlink,
        'x-network-strength-rtt': networkStrength.rtt,
      },
    }
  );
  }
};
const addProject = (project) => api.post("projects", project);
const updateProject = (boardId, project) =>
  api.put("projects/" + boardId, project);
const deleteProject = (boardId) => api.delete("projects/" + boardId);
const synchronizeProject = (boardId) => api.patch("projects/" + boardId);
const addBulkHours = (requestBody) => api.put("bulkhours/add", requestBody);
const removeBulkHours = (requestBody) => api.put("bulkhours/decrease", requestBody)

const projectService = {
  allProjects,
  projectDetail,
  addProject,
  updateProject,
  deleteProject,
  synchronizeProject,
  addBulkHours,
  removeBulkHours
};

export default projectService;
